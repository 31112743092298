import React from "react";
import {
  OrderPayBreakDownWithAllocation,
  OrderPayBreakDownWithAllocationResult,
} from "@api/graphql/generated/generated-types";
import { formatDescription } from "../../utils/formatHelpers";

interface DriverAllocationListProps {
  allocations: OrderPayBreakDownWithAllocationResult | undefined;
}

export function DriverAllocationList({
  allocations,
}: DriverAllocationListProps) {
  if (!allocations) return null;

  return (
    <div className="mb-4">
      {allocations.allocations.map((allocation) => (
        <DriverAllocationItem
          key={allocation.driverId}
          allocation={allocation}
        />
      ))}
    </div>
  );
}

interface DriverAllocationItemProps {
  allocation: OrderPayBreakDownWithAllocation;
}

function DriverAllocationItem({ allocation }: DriverAllocationItemProps) {
  const allocationPercent = (
    parseFloat(allocation.allocationRate) * 100
  ).toFixed(0);
  const { driver, breakdown } = allocation;

  return (
    <div className="mb-3 pb-3 border-b border-gray-200 last:border-0">
      <div className="flex justify-between items-center mb-1">
        <span className="font-medium">
          {driver?.firstName} {driver?.lastName}
        </span>
        <span className="text-xs text-gray-500">
          Allocation: {allocationPercent}%
        </span>
      </div>

      <div className="pl-2">
        {breakdown.items.map((item, idx) => (
          <BreakdownItem
            key={idx}
            name={item.name}
            description={item.description || "0%"}
            amount={item.amount}
          />
        ))}

        <div className="flex justify-between mt-1 font-medium text-sm">
          <span>Total</span>
          <span>${parseFloat(breakdown.total).toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
}

interface BreakdownItemProps {
  name: string;
  description: string;
  amount: string;
}

function BreakdownItem({ name, description, amount }: BreakdownItemProps) {
  return (
    <div className="flex justify-between text-sm">
      <span className="text-gray-600">
        {name} ({formatDescription(description)})
      </span>
      <span>${parseFloat(amount).toFixed(2)}</span>
    </div>
  );
}
