import { useEffect, useState } from "react";
import {
  OrderPayBreakDownWithAllocation,
  Shipment,
  useOrderPayBreakDownWithAllocationsQuery,
  useUpdateDriverPayOverrideMutation,
} from "@api/graphql/generated/generated-types";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useAuth } from "@src/common/hooks/useAuth";

interface UseOrderPayBreakdownLogicProps {
  selectedShipment: Shipment | null | undefined;
  setDriverPayTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  driverPayTotal: number | undefined;
}

export function useOrderPayBreakdownLogic({
  selectedShipment,
  setDriverPayTotal,
  driverPayTotal,
}: UseOrderPayBreakdownLogicProps) {
  const { courieUser, isEmployee, customClaims, courierId } = useAuth();
  const { showToast } = useCourieStore();
  const [isDriverCompensationOverride, setIsDriverCompensationOverride] =
    useState(false);

  const [
    updateDriverPayOverrideMutation,
    { loading: updateDriverPayOverrideMutationLoading },
  ] = useUpdateDriverPayOverrideMutation();

  const {
    data,
    loading: allocationsLoading,
    refetch,
  } = useOrderPayBreakDownWithAllocationsQuery({
    variables: {
      courierId: courierId || "",
      orderId: selectedShipment?.order.id || "",
    },
    skip: !courierId || !selectedShipment?.order.id,
    fetchPolicy: "network-only",
  });

  const allocationsData = data?.orderPayBreakDownWithAllocations;

  // Calculate total from all allocations
  useEffect(() => {
    if (allocationsData) {
      // Check if any allocation has "Overridden Compensation" item
      const hasOverride = allocationsData?.isOverride;

      setIsDriverCompensationOverride(hasOverride);

      const calculatedTotal = allocationsData.total;
      setDriverPayTotal(calculatedTotal);
    }
  }, [allocationsData, setDriverPayTotal]);

  const handleSave = () => {
    if (!selectedShipment) return;

    const variables = {
      variables: {
        orderId: selectedShipment.order.id,
        driverPayOverride: driverPayTotal,
        updatedBy: isEmployee
          ? "EMPLOYEE"
          : courieUser?.id || customClaims?.courieUserId || undefined,
      },
    };

    updateDriverPayOverrideMutation(variables)
      .then((res) => {
        if (res.data?.updateDriverPayOverride) {
          showToast({
            type: "success",
            message: "Driver pay override updated successfully",
          });
          refetch();
        }
      })
      .catch((e) => {
        showErrorToast(e, showToast);
      });
  };

  const handleUndoOverride = () => {
    if (!selectedShipment) return;

    const variables = {
      variables: {
        orderId: selectedShipment.order.id,
        driverPayOverride: null,
      },
    };

    updateDriverPayOverrideMutation(variables)
      .then((res) => {
        if (res.data?.updateDriverPayOverride) {
          showToast({
            type: "success",
            message: "Driver pay override removed successfully",
          });
          refetch();
        }
      })
      .catch((e) => {
        showErrorToast(e, showToast);
      });
  };

  return {
    allocationsData,
    allocationsLoading,
    isDriverCompensationOverride,
    updateDriverPayOverrideMutationLoading,
    handleSave,
    handleUndoOverride,
  };
}
