import React from "react";
import {
  OrderPayBreakDownWithAllocationResult,
  Shipment,
} from "@api/graphql/generated/generated-types";
import { Button, Label } from "@src/common/components";
import { Loading } from "@src/common/components/Loading";
import DollarInput from "@src/common/components/Input/DollarInput";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { useOrderPayBreakdownLogic } from "../hooks/useOrderPayBreakdownLogic";
import { DriverAllocationList } from "./components/DriverAllocationList";

type Props = {
  selectedShipment: Shipment | null | undefined;
  setDriverPayTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  driverPayTotal: number | undefined;
};

function OrderPayBreakDown({
  selectedShipment,
  setDriverPayTotal,
  driverPayTotal,
}: Props) {
  const {
    allocationsData,
    allocationsLoading,
    isDriverCompensationOverride,
    updateDriverPayOverrideMutationLoading,
    handleSave,
    handleUndoOverride,
  } = useOrderPayBreakdownLogic({
    selectedShipment,
    setDriverPayTotal,
    driverPayTotal,
  });

  if (allocationsLoading) {
    return <Loading loading={true} className="p-3 h-full overflow-auto" />;
  }

  return (
    <div className="shadow-md p-4 pt-2">
      <DriverAllocationList
        allocations={
          (allocationsData as OrderPayBreakDownWithAllocationResult) ||
          undefined
        }
      />

      <TotalSection
        driverPayTotal={driverPayTotal}
        setDriverPayTotal={setDriverPayTotal}
        isLoading={updateDriverPayOverrideMutationLoading}
      />

      <ControlButtons
        isDriverCompensationOverride={isDriverCompensationOverride}
        handleUndoOverride={handleUndoOverride}
        handleSave={handleSave}
        isDisabled={false}
        isLoading={updateDriverPayOverrideMutationLoading}
      />
    </div>
  );
}

function TotalSection({
  driverPayTotal,
  setDriverPayTotal,
  isLoading,
}: {
  driverPayTotal: number | undefined;
  setDriverPayTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  isLoading: boolean;
}) {
  return (
    <div className="flex flex-col pt-2">
      <Label className="font-bold">Total</Label>
      <Loading loading={isLoading}>
        <DollarInput
          dollar={Number(driverPayTotal) || 0}
          setDollar={(value) => setDriverPayTotal(value)}
          onChange={(event) => {
            const value = event.target.value;
            setDriverPayTotal(Number(value));
          }}
        />
      </Loading>
    </div>
  );
}

function ControlButtons({
  isDriverCompensationOverride,
  handleUndoOverride,
  handleSave,
  isDisabled,
  isLoading,
}: {
  isDriverCompensationOverride: boolean;
  handleUndoOverride: () => void;
  handleSave: () => void;
  isDisabled: boolean;
  isLoading: boolean;
}) {
  return (
    <div className="flex justify-between mt-4">
      {isDriverCompensationOverride ? (
        <span
          onClick={handleUndoOverride}
          className="flex truncate items-center text-xs cursor-pointer text-blue-500 hover:underline"
        >
          <ArrowUturnLeftIcon className="h-4 w-4 mr-1" />
          Undo Override
        </span>
      ) : (
        <div></div>
      )}
      <Button
        disabled={isDisabled || isLoading}
        size="xs"
        onClick={handleSave}
        isProcessing={isLoading}
      >
        Save Changes
      </Button>
    </div>
  );
}

export default OrderPayBreakDown;
