import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import OrderPayBreakDown from "../OrderPayBreakDown";
import { Shipment } from "@api/graphql/generated/generated-types";

type Props = {
  selectedSingleShipmentMemo: Shipment | null | undefined;
  setDriverPayTotal: (value: React.SetStateAction<number | undefined>) => void;
  driverPayTotal: number | undefined;
};

function DriverCompensation({
  selectedSingleShipmentMemo,
  setDriverPayTotal,
  driverPayTotal,
}: Props) {
  return (
    <div>
      <span className="font-semibold">Driver Compensation</span>
      <div className="rounded-lg mb-2 border shadow-md">
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500/75">
                <span>Allocations</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-slate-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-sm text-gray-500">
                <OrderPayBreakDown
                  selectedShipment={selectedSingleShipmentMemo}
                  setDriverPayTotal={setDriverPayTotal}
                  driverPayTotal={driverPayTotal}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default DriverCompensation;
