import {
  OrderPrice,
  usePreviewOrderPricingModificationMutation,
} from "@api/graphql/generated/generated-types";
import {
  PricingAdjustmentCreate,
  PricingAdjustmentType,
  SurchargePricingRule,
} from "@api/graphql/generated/generated-types";
import { EditableSurchargePricingRule } from "@src/orders/create/components/SurchagePricingRulesForm";
import { useErrorToast } from "./useErrorToast";

export interface UseUpdateTotalPriceProps {
  removedItems: Record<string, boolean>;
  surchargePricings: EditableSurchargePricingRule[];
  orderId?: string;
  orderPrice?: OrderPrice;
  setTotalPriceOverideValue: (value?: number) => void;
}

export function useUpdateTotalPrice({
  removedItems,
  surchargePricings,
  orderId,
  orderPrice,
  setTotalPriceOverideValue,
}: UseUpdateTotalPriceProps) {
  const [
    previewOrderPricingModificationMutation,
    { loading: previewOrderPricingModificationMutationLoading },
  ] = usePreviewOrderPricingModificationMutation();
  const showError = useErrorToast();

  const updateTotalPrice = () => {
    const adjustmentIdsToRemove = Object.entries(removedItems)
      .filter(([adjustmentId, isRemoved]) => isRemoved)
      .map(([adjustmentId]) => adjustmentId);

    let requestedPricingAdjustments: PricingAdjustmentCreate[] = [];
    surchargePricings.forEach((row: SurchargePricingRule) => {
      const isCustom = row.id ? false : true;
      if (isCustom) {
        requestedPricingAdjustments.push({
          amount_total: Number(row.amount),
          type: PricingAdjustmentType.Custom,
        });
      } else {
        requestedPricingAdjustments.push({
          type: PricingAdjustmentType.RuleBased,
          surchargePricingRuleId: row.id,
        });
      }
    });

    if (orderId) {
      previewOrderPricingModificationMutation({
        variables: {
          orderId,
          adjustmentsToAdd: requestedPricingAdjustments,
          adjustmentIdsToRemove,
        },
      })
        .then((result) => {
          const totalPricePriview =
            result.data?.previewOrderPricingModification.total;
          setTotalPriceOverideValue(totalPricePriview);
        })
        .catch((e) => showError(e));
    }
  };

  return { updateTotalPrice, previewOrderPricingModificationMutationLoading };
}
