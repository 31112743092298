import { ArrowUturnLeftIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PriceOverrideBreakdownItem } from "@api/graphql/generated/generated-types";
import classNames from "classnames";
import DollarInput from "@src/common/components/Input/DollarInput";
import { SIZE } from "baseui/select";
import { Button, TextInput } from "flowbite-react";

type Props = {
  showUndoButton: boolean;
  resetPriceOverride: () => void;
  breakdownItems: PriceOverrideBreakdownItem[];
  setBreakdownItems: (items: PriceOverrideBreakdownItem[]) => void;
  allowAddingItems: boolean;
};

function OrderPriceFormOverride({
  showUndoButton,
  resetPriceOverride,
  breakdownItems,
  setBreakdownItems,
  allowAddingItems,
}: Props) {
  const addBreakdownItem = () => {
    setBreakdownItems([...breakdownItems, {
      description: "",
      amount: 0,
    }]);
  };

  const deleteBreakdownItem = (index: number) => {
    const updatedItems = [...breakdownItems];
    updatedItems.splice(index, 1);
    setBreakdownItems(updatedItems);
  };

  return (
    <div className="p-4 pl-4">
      {breakdownItems?.map((item, index) => (
        <div key={"breakDown"+index} className="flex flex-row items-center mb-2 gap-2">
          <TextInput
            key={"breakDownName"+index}
            className="flex-grow"
            placeholder={"Enter an optional note"}
            sizing={"sm"}
            value={item.description ? item.description : ""}
            onChange={(e) => {
              // TODO: Whenever this changes, focus is lost. Fix this.
              const updatedItems = [...breakdownItems];
              updatedItems[index] = {
                ...updatedItems[index],
                description: e.target.value,
              };
              setBreakdownItems(updatedItems);
            }}
          />
          <div className="w-[125px]">
            <DollarInput
              size={SIZE.mini}
              dollar={Number(item.amount) || 0}
              setDollar={(dollar) => {
                const updatedItems = [...breakdownItems];
                updatedItems[index] = {
                  ...updatedItems[index],
                  amount: dollar,
                };
                setBreakdownItems(updatedItems);
              }}
            />
          </div>
          <Button
            size={"xs"}
            color={"secondary"}
            onClick={() => deleteBreakdownItem(index)}
          >
            <TrashIcon className="h-5 w-5 inline-block cursor-pointer hover:bg-slate-200 rounded-full p-0.5" />
          </Button>
        </div>
      ))}
      {allowAddingItems && (
        <div
          onClick={addBreakdownItem}
          className="flex cursor-pointer text-blue-500 hover:underline mb-4"
        >
          <PlusCircleIcon className="h-5 w-5 mr-2" />
          Add Breakdown Item
        </div>
      )}
      {showUndoButton && (
        <div className={classNames({
          "flex hover:underline cursor-pointer": true,
          "text-primary-500 hover:text-primary-700": true,
          })}
          onClick={resetPriceOverride}
        >
          <ArrowUturnLeftIcon className="h-4 w-4 mr-1" />
          Undo Price Override
        </div>  
      )}
    </div>
  );
}

export default OrderPriceFormOverride;