import React, { useContext, useEffect, useState } from "react";
import {
  OrderPrice,
  PriceOverrideBreakdownItem,
  PricingAdjustmentCreate,
  PricingAdjustmentType,
  PricingBreakDownItem,
  PricingList,
  Shipment,
  SurchargePricingRule,
  useModifyOrderPricingMutation,
} from "@api/graphql/generated/generated-types";
import { Button, Loading, Label } from "@src/common/components";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import DollarInput from "../../../Input/DollarInput";
import SurchagePricingRulesForm, {
  EditableSurchargePricingRule,
} from "@src/orders/create/components/SurchagePricingRulesForm";
import { useDebounce } from "usehooks-ts";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import PricingBreakDownItemEdit from "./PricingBreakDownItemEdit";
import PricingBreakDownItemComponent from "./PricingBreakDownItemComponent";
import { AuthContext } from "@src/auth/components/AuthProvider";
import OrderPriceFormOverride from "./OrderPriceFormOverride";
import { useUpdateTotalPrice } from "@src/common/hooks/useUpdateTotalPrice";
import { useActionBy } from "@src/common/hooks/useActionBy";

export const OrdersPriceForm = ({
  selectedShipment,
  onModifyOrderPricing,
  orderPrice: orderPriceProp,
}: {
  selectedShipment: Shipment | null | undefined;
  onModifyOrderPricing?: () => void;
  orderPrice?: OrderPrice | undefined;
}) => {
  const { showToast } = useCourieStore();
  const { updatedBy } = useActionBy();
  const { courieUser, isEmployee, customClaims } = useContext(AuthContext);
  const [
    modifyOrderPricingMutation,
    {
      data: modifyOrderPricingMutationData,
      loading: modifyOrderPricingMutationLoading,
      error: modifyOrderPricingMutationError,
    },
  ] = useModifyOrderPricingMutation();
  const [isOverrideInputDirty, setIsOverrideInputDirty] = useState(false);
  const [removedItems, setRemovedItems] = useState<Record<string, boolean>>({});
  const [orderPrice, setOrderPrice] =
    useState<OrderPrice | undefined>(undefined);
  const removedItemsDebounced = useDebounce<any>(removedItems, 500);
  const [totalPriceOverideValue, setTotalPriceOverideValue] =
    useState<undefined | number>(0);
  const [surchargePricings, setSurchargePricings] = useState<
    EditableSurchargePricingRule[]
  >([]);
  const [basePriceOverrideMode, setBasePriceOverrideMode] = useState(false);
  const [basePriceOverrideValue, setBasePriceOverrideValue] = useState(0);
  const [editItems, setEditItems] = useState<
    Record<number, PricingBreakDownItem>
  >({});
  const [breakdownItems, setBreakdownItems] = useState<PriceOverrideBreakdownItem[]>([]);
  const orderId = selectedShipment?.order.id;
  const selectedPricingListId = selectedShipment?.order.endCustomer.pricingList;

  const { updateTotalPrice, previewOrderPricingModificationMutationLoading } =
    useUpdateTotalPrice({
      removedItems: removedItemsDebounced,
      surchargePricings,
      orderId: selectedShipment?.order.id,
      orderPrice,
      setTotalPriceOverideValue,
    });

  useEffect(() => {
    if (orderPriceProp) {
      setIsOverrideInputDirty(orderPriceProp.isPriceOverride);
      if (orderPriceProp.basePriceOverride) {
        setBasePriceOverrideValue(orderPriceProp.basePriceOverride);
      } else {
        setBasePriceOverrideValue(0);
      }
      setBreakdownItems(orderPriceProp.priceOverrideBreakdown || []);
    }
    if (orderPriceProp) {
      setOrderPrice(orderPriceProp);
      setTotalPriceOverideValue(orderPriceProp.total);
      setRemovedItems({});
      setEditItems({});
    }
  }, [orderPriceProp]);

  useEffect(() => {
    if (modifyOrderPricingMutationError) {
      showErrorToast(modifyOrderPricingMutationError, showToast);
    }
  }, [modifyOrderPricingMutationError]);

  useEffect(() => {
    if (surchargePricings?.length === 0) {
      return;
    }
    updateTotalPrice();
  }, [selectedShipment, surchargePricings]);

  useEffect(() => {
    if (Object.keys(removedItemsDebounced).length === 0) {
      return;
    }
    updateTotalPrice();
  }, [selectedShipment, removedItemsDebounced]);

  const onSave = () => {
    const adjustmentIdsToRemove = Object.entries(removedItemsDebounced)
      .filter(([adjustmentId, isRemoved]) => isRemoved)
      .map(([adjustmentId]) => adjustmentId);
    if (orderId != undefined && totalPriceOverideValue != undefined) {
      let requestedPricingAdjustments: PricingAdjustmentCreate[] = [];
      surchargePricings.forEach((row: SurchargePricingRule) => {
        const isCustom = row.id ? false : true;
        if (isCustom) {
          requestedPricingAdjustments.push({
            amount_total: Number(row.amount),
            type: PricingAdjustmentType.Custom,
          });
        } else {
          requestedPricingAdjustments.push({
            type: PricingAdjustmentType.RuleBased,
            surchargePricingRuleId: row.id,
          });
        }
      });

      const editItemCount = Object.entries(editItems).length;

      if (isOverrideInputDirty && breakdownItems.length > 0) {
        const breakdownSum = breakdownItems.reduce((acc, item) => acc + Number(item.amount), 0);
        if (breakdownSum != totalPriceOverideValue) {
          showToast({
            message: "Breakdown items do not match total price override",
            type: "error",
          });
          return;
        }
      }

      modifyOrderPricingMutation({
        variables: {
          orderId,
          adjustmentsToAdd: isOverrideInputDirty
            ? []
            : requestedPricingAdjustments,
          adjustmentIdsToRemove: isOverrideInputDirty
            ? []
            : adjustmentIdsToRemove,
          priceOverride: isOverrideInputDirty
            ? totalPriceOverideValue
            : undefined,
          priceOverrideBreakdown: breakdownItems.length > 0
            ? breakdownItems.map(({ description, amount }) => ({ description, amount }))
            : undefined,
          basePriceOverride:
            basePriceOverrideMode || basePriceOverrideValue
              ? basePriceOverrideValue
              : null,
          adjustmentOverrides:
            editItemCount > 0
              ? Object.entries(editItems).map(([adjustmentId, { amount }]) => ({
                  adjustmentId: adjustmentId,
                  amount: amount || 0,
                }))
              : undefined,
          updatedBy: updatedBy,
        },
      }).then((result) => {
        onModifyOrderPricing && onModifyOrderPricing();
        setSurchargePricings([]);
        setBasePriceOverrideMode(false);
      }).catch((e) => {
        showErrorToast(e, showToast);
      });
    }
  };

  const handleUndoOverride = () => {
    if (orderId && totalPriceOverideValue) {
      const variables = {
        orderId,
        adjustmentsToAdd: undefined,
        adjustmentIdsToRemove: undefined,
        priceOverride: undefined,
        basePriceOverrideValue: basePriceOverrideValue,
        updatedBy: isEmployee
          ? "EMPLOYEE"
          : courieUser?.id || customClaims?.courieUserId || undefined,
      };
      modifyOrderPricingMutation({
        variables,
      }).then((result) => {
        onModifyOrderPricing && onModifyOrderPricing();
        setIsOverrideInputDirty(false);
      });
    }
  };

  const undoBasePriceOverride = () => {
    if (orderId) {
      modifyOrderPricingMutation({
        variables: {
          orderId,
          basePriceOverride: null,
          updatedBy: isEmployee
            ? "EMPLOYEE"
            : courieUser?.id || customClaims?.courieUserId || undefined,
        },
      }).then((result) => {
        onModifyOrderPricing && onModifyOrderPricing();
        setIsOverrideInputDirty(false);
      });
    }
  };

  if (orderPrice) {
    return (
      <div className="order-price-form container mx-auto rounded-md px-2">
        {!isOverrideInputDirty ? (
          <>
            {orderPrice &&
              orderPrice.pricingBreakDown.items.map((item, i) => {
                const adjustmentId = item.adjustmentId || "";
                return (
                  <React.Fragment key={i}>
                    {editItems[adjustmentId] ? (
                      <PricingBreakDownItemEdit
                        setEditItems={setEditItems}
                        pricingBreakDownItem={item}
                      />
                    ) : (
                      <PricingBreakDownItemComponent
                        basePriceOverrideMode={basePriceOverrideMode}
                        setBasePriceOverrideMode={setBasePriceOverrideMode}
                        PricingBreakDownItem={item}
                        removedItems={removedItems}
                        basePriceOverrideValue={basePriceOverrideValue}
                        setBasePriceOverrideValue={setBasePriceOverrideValue}
                        index={i}
                        setRemovedItems={setRemovedItems}
                        undoBasePriceOverride={undoBasePriceOverride}
                        orderPrice={orderPrice}
                        setEditItems={setEditItems}
                        onModifyOrderPricing={onModifyOrderPricing}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            {!modifyOrderPricingMutationLoading && (
              <SurchagePricingRulesForm
                surchargePricings={surchargePricings}
                setSurchargePricings={setSurchargePricings}
                setValue={() => {}}
                isCompactList={true}
                showAddButton={!orderPrice.isPriceOverride}
                pricingList={selectedPricingListId as PricingList | undefined}
              />
            )}
          </>
        ) :  (
          <OrderPriceFormOverride
            showUndoButton={!orderPrice.isPriceOverride}
            resetPriceOverride={() => {
              setTotalPriceOverideValue(orderPrice.total);
              setIsOverrideInputDirty(false);
              setRemovedItems({});
              setSurchargePricings([]);
              setBreakdownItems([]);
            }}
            breakdownItems={breakdownItems}
            setBreakdownItems={setBreakdownItems}
            allowAddingItems={(isOverrideInputDirty && totalPriceOverideValue && totalPriceOverideValue != 0) || false}
          />
        )}
        <div>
          <Label className="font-bold">Total</Label>
          <Loading loading={previewOrderPricingModificationMutationLoading}>
            <DollarInput
              dollar={Number(totalPriceOverideValue) || 0}
              setDollar={(value) => setTotalPriceOverideValue(value)}
              onChange={(event) => {
                const value = event.target.value;
                setTotalPriceOverideValue(Number(value));
                if (orderPrice.total !== Number(value)) {
                  setIsOverrideInputDirty(true);
                }
              }}
            />
          </Loading>
        </div>
        <div className="flex justify-between mt-4 ">
          {orderPrice.isPriceOverride ? (
            <span
              onClick={() => handleUndoOverride()}
              className="flex truncate items-center text-xs cursor-pointer text-blue-500 hover:underline"
            >
              <ArrowUturnLeftIcon className="h-4 w-4 mr-1" />
              Undo Override
            </span>
          ) : (
            <div></div>
          )}
          <Button
            size="xs"
            onClick={() => onSave()}
            isProcessing={modifyOrderPricingMutationLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>
    );
  }
  return null;
};
